import { useState, useContext, useMemo, useCallback } from 'react';

import {
  Box,
  IconButton,
  Text,
  HStack,
  Divider,
  CloseButton,
  Badge,
  Flex,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';

import style from './NotificationBanner.style';
// import { notificationDetails } from './dummy';
import VendorRatingModal from '../VendorRatingModal/vendorRatingModal';

import { Icon, Modal } from '@/components';
import CenteredLoader from '@/components/organism/dashboard/CenteredLoader/CenteredLoader';
import { IVendor } from '@/components/organism/dashboard/Profile/Profile.type';
import useGetVendors from '@/components/organism/dashboard/Profile/hooks/useGetVendors';
import { transformVendorData } from '@/components/organism/dashboard/Profile/utils';
import { convertTimeZoneNotification } from '@/helpers/date';
import { mapStageNameToNavigationRoute } from '@/helpers/mapStageNametoRoutes';
import useTranslation from '@/hooks/useTranslate';
import { DashboardContext } from '@/providers/DashboardContext';
import {
  INotificationOptionalType,
  ISiteVisit,
  IVendorType,
} from '@/types/dashboardContext.type';

const NotificationBanner = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    notificationDetails,
    markAsReadNotification,
    vendorRatingLoading,
    submitVendorRating,
    vendorRatingSubmitLoader,
    updateUserContext,
  } = useContext(DashboardContext);
  const { vendors } = useGetVendors();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenValidModal, setIsOpenValidModal] = useState<boolean>(false);
  const [formattedData, setFormattedData] = useState<any>([]);
  const [isSiteVisit, setSiteVisit] = useState<boolean>(false);
  const vendorDetails = transformVendorData(vendors);

  /* Added for Redirection */
  const handleNavigateToNotificationDetails = useCallback(
    (
      sfStageName: string | undefined,
      serviceRequestId?: string,
      currentTab?: string
    ) => {
      const slug = mapStageNameToNavigationRoute(sfStageName);

      if (currentTab) {
        return router.push(`${slug}?id=${serviceRequestId}/${currentTab}`);
      } else {
        return router.push(`${slug}?id=${serviceRequestId}`);
      }
    },
    []
  );

  const notificationCount = useMemo(() => {
    return Array.isArray(notificationDetails)
      ? notificationDetails.filter(item => !item.isRead).length
      : 0;
  }, [notificationDetails]);

  const filteredData = useCallback(
    (notificationIds: string | any) => {
      const result = vendorDetails.filter((vendorItem: IVendor) =>
        notificationIds.includes(vendorItem?.id)
      );
      return result?.length > 0 ? result : false;
    },
    [vendors]
  );

  const handleNotificationClick = ({
    isRead,
    id,
    notificationType,
    sfStageName,
    serviceRequestId,
    notificationData, // serviceRequestStatus,
  }: INotificationOptionalType) => {
    try {
      setIsOpen(false);
      if (!isRead) {
        markAsReadNotification({ id, isRead: !isRead });
      }
      if (notificationType === 'vendorRatingSurvey') {
        handleVendorRating(notificationData);
      } else if (
        notificationType === 'siteVisitReschedule' ||
        notificationType === 'siteVisit' ||
        notificationType === 'siteVisitReminder'
      ) {
        handleSiteVisit(notificationData, sfStageName, serviceRequestId);
        // } else if (
        //   serviceRequestStatus === 'Deactivated' ||
        //   serviceRequestStatus === 'Closed'
        // ) {
        //   handleNavigateToNotificationDetails(
        //     sfStageName,
        //     serviceRequestId,
        //     serviceRequestStatus
        //   );
      } else {
        handleNavigateToNotificationDetails(sfStageName, serviceRequestId);
      }

      return;
    } catch (error) {
      console.error(error, 'Error while clicking on notification details');
    }
  };

  /* To handle vendor rating */
  const handleVendorRating = (notificationData: IVendorType) => {
    const isValid = filteredData(notificationData?.vendorIds);
    if (!isValid) {
      setIsOpenValidModal(true);
    } else {
      setFormattedData(isValid);
      setIsOpenModal(true);
    }
  };

  /* To handle Site visit */
  const handleSiteVisit = (
    notificationData: ISiteVisit,
    sfStageName: string,
    serviceRequestId: string
  ) => {
    const { isExpired } = convertTimeZoneNotification(notificationData);
    if (!isExpired) {
      setSiteVisit(true);
      setIsOpenValidModal(true);
    } else {
      handleNavigateToNotificationDetails(sfStageName, serviceRequestId);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  /* Added for Submit Vendor Rating Form */
  const handleSubmit = async (data: any) => {
    try {
      const response: any = await submitVendorRating(data);
      if (response) {
        setIsOpenModal(false);
        await updateUserContext();
      }
    } catch (error) {
      console.error(
        error,
        'Error while submitting vendor rating Form from notification banner'
      );
    }
  };

  const handleCloseConditionalModal = () => {
    setIsOpenValidModal(!isOpenValidModal);
  };

  return (
    <Box sx={style.baseStyle?.root}>
      <Box>
        <Box sx={style.baseStyle?.initialDiv}>
          <IconButton
            icon={<Icon name="bellIcon" />}
            aria-label="Notifications"
            onClick={() => setIsOpen(!isOpen)}
            variant="ghost"
            fontSize="1.8rem"
            position="relative"
            _hover={{
              backgroundColor: 'white',
            }}
          />

          {vendorRatingLoading ? ( // Show loader when fetching count
            <Badge sx={style.baseStyle?.badge}>
              <Spinner size="xs" color="white" />
            </Badge>
          ) : (
            notificationCount > 0 && (
              <Badge sx={style.baseStyle?.badge}>{notificationCount}</Badge>
            )
          )}
        </Box>

        {isOpen && (
          <Box sx={style.baseStyle?.body}>
            <Box sx={style.baseStyle?.secondDiv}>
              <HStack justifyContent="space-between">
                <Text sx={style.baseStyle?.header}>
                  {t('portal_notifications_screen_title')}
                </Text>
                <CloseButton
                  onClick={() => setIsOpen(!isOpen)}
                  sx={style.baseStyle?.closeIcon}
                />
              </HStack>
            </Box>
            <Divider />
            <Box
              sx={
                notificationDetails?.length > 5
                  ? style.baseStyle?.scrollDiv
                  : style.baseStyle?.thirdDiv
              }
            >
              {vendorRatingLoading ? (
                <CenteredLoader variant="guideSkeleton" />
              ) : Array.isArray(notificationDetails) &&
                notificationDetails.length > 0 ? (
                notificationDetails?.map((item, index) => {
                  return (
                    <Box
                      key={item?.id}
                      sx={{
                        ...style.baseStyle?.conditionFalse,
                        bg: item?.isRead ? '#F7FAFC' : 'white', // Gray out if read
                        opacity: item?.isRead ? 0.6 : 1, // Reduce opacity if read
                        transition: '0.3s',
                      }}
                      _hover={{ bg: '#E2E8F0' }}
                    >
                      <Box
                        cursor="pointer"
                        onClick={() => {
                          handleNotificationClick(item);
                        }}
                        sx={style.baseStyle?.initialBox}
                      >
                        <Box sx={{ display: 'grid', gap: '16px' }}>
                          <HStack sx={style.baseStyle?.initialHStack}>
                            <Box sx={style.baseStyle?.icon}>
                              <Icon
                                name={item?.isRead ? 'greyDots' : 'yellowDots'}
                                sx={style.baseStyle?.iconStyle}
                              />
                            </Box>
                            <Text sx={style.baseStyle?.title}>
                              {item?.title}
                            </Text>
                          </HStack>
                          <Text sx={style.baseStyle?.description}>
                            {item?.body}
                          </Text>
                        </Box>
                      </Box>

                      {index < notificationDetails.length - 1 && (
                        <Divider mt={4} />
                      )}
                    </Box>
                  );
                })
              ) : (
                <Box sx={style.baseStyle?.noDetails}>
                  <Text sx={style.baseStyle?.noContent}>
                    {t('portal_notification_empty')}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <VendorRatingModal
          isOpen={isOpenModal}
          FormSubmit={handleSubmit}
          onClose={handleClose}
          data={formattedData}
          vendorRatingLoading={vendorRatingSubmitLoader}
        ></VendorRatingModal>
      </Box>
      <Box>
        <Modal
          isOpen={isOpenValidModal}
          onClose={handleCloseConditionalModal}
          showCloseButton={false}
          closeOnOverlayClick={false}
          size={'4xl'}
        >
          <Flex direction={'column'} gap={isSiteVisit ? '20px' : '16px'}>
            <Flex justifyContent={'center'}>
              <Icon name="arrows" sx={style.baseStyle?.modalIcon} />
            </Flex>

            {isSiteVisit && (
              <Flex direction={'column'}>
                <Text sx={style.baseStyle?.siteVisitTitle}>
                  {t('portal_site_visit_expiry_title')}
                </Text>
              </Flex>
            )}

            <Flex direction={'column'}>
              <Text
                sx={
                  isSiteVisit
                    ? style.baseStyle?.siteVisitDescription
                    : style.baseStyle?.validTitle
                }
              >
                {!isSiteVisit
                  ? t('portal_conditional_modal_tile')
                  : t('portal_site_visit_expiry_description')}
              </Text>
            </Flex>

            <Flex justifyContent={'center'} mt={'10px'}>
              <Button
                variant="solid"
                onClick={handleCloseConditionalModal}
                sx={style.baseStyle?.closeButton}
              >
                {t('close')}
              </Button>
            </Flex>
          </Flex>
        </Modal>
      </Box>
    </Box>
  );
};

export default NotificationBanner;
