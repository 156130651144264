import { useState, useEffect, useContext } from 'react';

import {
  FormControl,
  FormLabel,
  Textarea,
  Checkbox,
  HStack,
  IconButton,
  Text,
  VStack,
  Box,
  Divider,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import {
  ReviewFormData,
  PayloadData,
  ReviewModalProps,
} from './vendorRating.type';
import style from './vendorRatingModal.style';
import FormModal from '../FormModal/FormModal';

import { Icon } from '@/components';
import useTranslation from '@/hooks/useTranslate';
import { Context } from '@/providers/MainContext';

const ReviewModal = ({
  isOpen,
  onClose,
  FormSubmit,
  data,
  vendorRatingLoading,
  vendorType,
}: ReviewModalProps) => {
  const { t } = useTranslation();
  const { locale } = useContext(Context);
  const [ratings, setRatings] = useState<{ [vendorId: string]: number }>({});
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ReviewFormData>();

  useEffect(() => {
    if (!isOpen) {
      setRatings({});
      reset();
    }
  }, [isOpen, reset]);

  const handleRating = (vendorId: string, index: number) => {
    setRatings(prev => ({
      ...prev,
      [vendorId]: prev[vendorId] === index ? index - 1 : index,
    }));
    setValue(`ratings.${vendorId}`, index);
  };

  const onSubmit = async (details: ReviewFormData) => {
    const payloadData: PayloadData | any = {
      data: data?.map(vendor => ({
        id: vendor?.vendorSurveyId,
        response: {
          rating: details.ratings[vendor.id] || 0,
          userInput: details.reviews[vendor.id] || '',
        },
        anonymous: details?.anonymous,
        isScoreCardAvailable: vendor?.vendorSurveyId ? true : false,
        vendorType:
          vendor?.vendorType?.toLowerCase() || vendorType?.toLowerCase(),
      })),
    };
    // console.warn(payloadData, 'payloadData');
    FormSubmit(payloadData);
  };

  return (
    <FormModal
      isOpen={isOpen}
      title={t('rate_vendor_popup_title')}
      confirmText={t('portal_submit')}
      cancelText={t('close')}
      onCancel={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isConfirmDisabled={!data?.every(vendor => ratings[vendor?.id])}
      icon={false}
      isVendor={true}
      showCloseButton={true}
      isVendorRating={true}
      isLoading={vendorRatingLoading}
    >
      <VStack spacing={4} align="stretch" sx={style.VStacks}>
        {data?.map((vendor, index) => (
          <Box key={index}>
            {index > 0 && <Divider sx={style.borders} />}

            <VStack
              key={vendor?.id}
              spacing={3}
              align="stretch"
              pb={3}
              gap={'20px'}
            >
              <HStack sx={{ justifyContent: 'space-between' }}>
                <FormLabel sx={style.companyName}>
                  {locale !== 'ar'
                    ? vendor?.companyName
                    : vendor?.companyNameAr}
                </FormLabel>
                <Box sx={style.vendorType}>
                  <FormLabel sx={style.vendorTypeLabel}>
                    {t(vendor?.vendorType || '') || t(vendorType || '')}
                  </FormLabel>
                </Box>
              </HStack>
              <FormControl isInvalid={!!errors?.ratings?.[vendor?.id]}>
                <HStack mt={'10px'}>
                  {Array.from({ length: 5 }, (_, i) => (
                    <IconButton
                      key={i}
                      icon={
                        <Icon
                          name={
                            i < (ratings[vendor?.id] || 0)
                              ? 'BigFilledStar'
                              : 'BigNormalStar'
                          }
                          sx={style.starIcons}
                        />
                      }
                      aria-label={`Rate ${i + 1}`}
                      onClick={() => handleRating(vendor?.id, i + 1)}
                      variant="ghost"
                      _hover={{ backgroundColor: 'transparent' }}
                    />
                  ))}
                </HStack>
                {errors.ratings?.[vendor?.id] && (
                  <Text sx={{ color: 'red.500' }}>Please select a rating</Text>
                )}
              </FormControl>
              <FormControl mt={'20px'}>
                <Textarea
                  {...register(`reviews.${vendor?.id}`)}
                  placeholder={t('rate-vendor-form-placeholder')}
                  sx={style.textAreas}
                />
              </FormControl>
            </VStack>
          </Box>
        ))}

        <FormControl sx={style.lastContainer}>
          <FormLabel sx={style.lastTitle}>
            {t('rate_vendor_popup_anonymous_text')}
          </FormLabel>
          <Checkbox {...register('anonymous')} sx={style.checkboxes} />
        </FormControl>
      </VStack>
    </FormModal>
  );
};

export default ReviewModal;
