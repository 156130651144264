import { useContext } from 'react';

import { DashboardContext } from '@/providers/DashboardContext';
import { ICalculatorTabs } from '@/types/user.type';

export const generateSelectedVillaOptions = (
  calculatorTabs: Array<ICalculatorTabs> | null | undefined
): Record<string, string | undefined> | undefined => {
  if (!calculatorTabs) return undefined;

  return calculatorTabs?.reduce<Record<string, string | undefined>>(
    (acc, tab) => {
      const { calculatorQuestions } = tab;

      if (calculatorQuestions.length >= 1) {
        const question = calculatorQuestions[0];
        const selectedOption = question.calculatorOptions.find(
          option => option.isSelected
        );

        const calculatorQuestionRoomSelections =
          question.calculatorQuestionRoomSelections.length;

        if (question.questionTag) {
          acc[question.questionTag] = selectedOption
            ? selectedOption?.title
            : calculatorQuestionRoomSelections.toString();
        }
      }

      return acc;
    },
    {}
  );
};

/* ****** To redirect the user to current stage Start *******/
export const getCurrentStage = () => {
  const { user, serviceStages } = useContext(DashboardContext);
  const getCurrentStageDetails = serviceStages?.find(
    item => item.name == user?.userInfo?.projectInfo?.phase
  );
  const getStageTitle: any = user?.userInfo?.projectInfo?.phase;
  return { getCurrentStageDetails, getStageTitle };
};
export const transformVendorData = (apiResponse: any) => {
  if (!apiResponse) return [];

  return Object.keys(apiResponse)
    .map((key: any) => ({
      ...apiResponse[key], // Spread object data
      vendorType: key === 'awardedConsultant' ? 'Consultant' : 'Contractor',
    }))
    .filter(vendor => vendor.vendorSurveyPending); // Keep only vendors where vendorSurveyPending is true
};
