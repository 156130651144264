import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

import FontSizes from '@/styles/themes/brand/fontSizes';
import FontWeights from '@/styles/themes/brand/fontWeights';

const notificationBannerStyle = defineStyle({
  display: 'flex',
});

const style = defineStyleConfig({
  baseStyle: {
    root: notificationBannerStyle,
    badge: {
      position: 'absolute',
      width: '15px',
      height: '15px',
      // paddingRight: '3px',
      left: '12px',
      bottom: '15px',
      bg: ' #E02A2A',
      color: 'white',
      borderRadius: 'full',
      fontSize: FontSizes.xSmall,
      fontWeight: FontWeights.medium,
      textAlign: 'center',
    },
    body: {
      position: 'absolute',
      top: { base: '66px', lg: '100px' },
      _rtl: {
        left: '0',
      },
      _ltr: {
        right: '0',
      },
      width: { base: '100%', sm: '55%', md: '55%', lg: '40%' },
      bg: 'white',
      boxShadow: 'lg',
      borderRadius: 'md',
      zIndex: '10',
    },

    scrollDiv: {
      maxHeight: '400px',
      overflowY: 'auto',
      scrollbarColor: '#888 #f1f1f1',
    },
    thirdDiv: {
      maxHeight: 'auto',
      overflowY: 'unset',
    },
    noDetails: {
      maxHeight: '400px',
      padding: '24px',
      textAlign: 'center',
    },
    noContent: {
      fontWeight: FontWeights.bold,
      fontSize: FontSizes.normal,
    },

    notificationDetails: {
      padding: '24px',
      bg: '#F9FAFB',
      borderRadius: 'md',
      w: 'full',
      cursor: 'pointer',
      border: '1px solid',
      borderColor: '#E2E8F0',
    },
    initialDiv: {
      position: 'relative',
    },
    secondDiv: { padding: '24px' },
    header: { fontSize: FontSizes.xMedium, fontWeight: FontWeights.medium },
    validTitle: {
      fontSize: FontSizes.xMedium,
      fontWeight: FontWeights.normal,
      lineHeight: '160%',
      textAlign: 'center',
    },
    siteVisitTitle: {
      fontSize: FontSizes.large,
      fontWeight: FontWeights.medium,
      // lineHeight: '30%',
      textAlign: 'center',
      // paddingTop: '10px',
    },
    siteVisitDescription: {
      fontSize: FontSizes.small,
      fontWeight: FontWeights.normal,
      lineHeight: '160%',
      textAlign: 'center',
      // paddingTop: '10px',
    },
    conditionTrue: {
      border: '1px solid #D6D6D6',
    },
    conditionFalse: {
      boxShadow: '0px 4px 44px 0px #00000012',
    },
    title: {
      fontWeight: FontWeights.bold,
      fontSize: FontSizes.normal,
    },
    description: {
      // marginTop: '2px',
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.small,
    },
    icon: { width: '8px', height: '8px', borderRadius: 'full' },
    iconStyle: {
      _rtl: { width: '12px', height: '24px' },
      _ltr: { width: '24px', height: '24px' },
    },
    closeIcon: {
      '& svg': {
        width: '12px',
        height: '20px',
      },
      _hover: {
        bg: 'transparent',
      },
    },
    initialBox: { padding: '24px' },
    modalIcon: {
      height: '66px',
      width: '60px',
    },
    closeButton: {
      border: '1px solid #171D38',
      backgroundColor: 'white',
      color: '#171D38',
      _hover: { backgroundColor: 'white' },
    },
    initialHStack: { _rtl: { gap: '8px' }, _ltr: { gap: '8px' } },
  },
});

export default style;
