const stageList = [
  {
    name: 'Onboarding',
    path: '/dashboard/services/your-requirements',
  },
  {
    name: 'Consultant Procurement',
    path: '/dashboard/services/hire-consultant',
  },
  {
    name: 'Villa Design',
    path: '/dashboard/services/design-your-vila',
  },
  {
    name: 'Contractor Procurement',
    path: '/dashboard/services/hire-contractor',
  },
  {
    name: 'Construction',
    path: '/dashboard/services/build-your-vila',
  },
  {
    name: 'Handover and DLP',
    path: '/dashboard/services/your-handover',
  },
];

export const mapStageNameToNavigationRoute = (
  sfStageName: string | undefined
) => {
  const slug = stageList.find(stage => stage.name === sfStageName)
    ?.path as string;

  return slug;
};
