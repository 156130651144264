import { defineStyle } from '@chakra-ui/react';

import FontSizes from '@/styles/themes/brand/fontSizes';
import FontWeights from '@/styles/themes/brand/fontWeights';

const style = defineStyle({
  VStacks: { width: '100%', gap: '32px' },
  companyName: { fontSize: '19px', fontWeight: '500' },
  vendorType: {
    padding: '6px 16px ',
    borderRadius: '14px',
    background: '#EAEAEAB8',
  },
  vendorTypeLabel: {
    fontSize: FontSizes.xSmall,
    fontWeight: FontWeights.medium,
    margin: '0',
    textAlign: 'center',
    color: '#00000',
  },
  lastContainer: {
    display: 'flex',
    flexDir: 'row',
    justifyContent: 'space-between',
  },
  lastTitle: { fontSize: '16px', fontWeight: '500' },
  checkboxes: {
    boxSize: '10',
    '& .chakra-checkbox__control': {
      width: '100%',
      height: '100%',
      border: '1px solid #D6D6D6',
      borderRadius: '5px',
      background: 'transparent !important',
    },
    '& .chakra-checkbox__icon': {
      fontSize: '5rem',
    },
    '& svg': {
      width: '1.5rem',
    },
    '& .chakra-checkbox__control[data-checked]': {
      bg: '#C29B40 !important', // Override default checked background
      borderColor: '#C29B40',
    },
    '& .chakra-checkbox__control[data-checked] svg': {
      color: 'white',
    },
    '& .chakra-checkbox__control:focus, & .chakra-checkbox__control[data-focus]':
      {
        boxShadow: 'none !important', // Remove focus ring
      },
    '& .chakra-checkbox__control:hover': {},
    '&:focus': {
      boxShadow: 'none !important',
    },
  },
  borders: {
    border: '1px solid #D6D6D6B8',
    marginBottom: '30px',
  },
  textAreas: { height: '120px', fontSize: '12px', fontWeight: '400' },
  starIcons: { height: '34px', width: '36px' },
});

export default style;
